import { SortingOptions } from '@clover/core/helpers/paging';
import { IProduct, ProductSortFields } from './products.entities';

export class LoadProduct {
  static readonly type = '[Product] Load Products';

  constructor(
    public payload: {
      query?: string;
      sortingOptions?: SortingOptions<ProductSortFields>;
      workListId?: number;
    },
  ) {}
}

export class LoadNextProduct {
  static readonly type = '[Product] Load Next Products';
}

export class SelectAll {
  static readonly type = '[Product] Select All';

  constructor(public payload: boolean) {}
}

export class SelectOne {
  static readonly type = '[Product] Select One';

  constructor(public selectedProduct: IProduct) {}
}

export class LoadWorklists {
  static readonly type = '[Product] Load Worklists';
}

export class ChangeWorkList {
  static readonly type = '[Product] Change Worklist';

  constructor(public workListId: number) {}
}

export class RemoveFromWorklist {
  static readonly type = '[Product] Remove From Worklist';
}

export class ClearProductState {
  static readonly type = '[Product] Clear State';
}