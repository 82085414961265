import { Action, State, StateContext } from '@ngxs/store';
import { INITIAL_STATE, ProductStateModel } from './products.entities';
import { ProductsService } from './products.service';
import { inject, Injectable } from '@angular/core';
import { UserService } from '@clover/core/services/user.service';
import { tap } from 'rxjs';
import {
  ChangeWorkList,
  ClearProductState, LoadNextProduct,
  LoadProduct,
  LoadWorklists,
  RemoveFromWorklist,
  SelectAll,
  SelectOne
} from './products.actions';
import * as R from 'ramda';
import { append, patch } from '@ngxs/store/operators';

@State<ProductStateModel>({
  name: 'products',
  defaults: INITIAL_STATE,
})
@Injectable()
export class ProductsState {
  private readonly productsService = inject(ProductsService);
  private readonly userService = inject(UserService);

  @Action(LoadProduct, { cancelUncompleted: true })
  loadProduct(ctx: StateContext<ProductStateModel>, { payload }: LoadProduct) {
    const state = ctx.getState();
    const query = R.isNil(payload.query) ? state.query : payload.query;
    const sorting = payload.sortingOptions || state.sorting;
    const companyId = this.userService.userProfile.companyId;
    const workListId = payload.workListId ? payload.workListId : state.workListId;

    return this.productsService
      .getProducts(
        companyId,
        query,
        {
          offset: 0,
          orderBy: sorting.orderBy,
          order: sorting.order,
          limit: 30,
        },
        workListId,
      )
      .pipe(
        tap((response) => {
          ctx.patchState({
            products: response,
          });
        }),
      );
  }

  @Action(LoadNextProduct, { cancelUncompleted: true })
  loadNextProduct(ctx: StateContext<ProductStateModel>) {
    const state = ctx.getState();
    const query = state.query;
    const sorting = state.sorting;
    const companyId = this.userService.userProfile.companyId;
    const workListId = state.workListId;

    return this.productsService
      .getProducts(
        companyId,
        query,
        {
          offset: state.products.data.length,
          orderBy: sorting.orderBy,
          order: sorting.order,
          limit: 30,
        },
        workListId,
      )
      .pipe(
        tap((response) => {
          ctx.setState(
            patch<ProductStateModel>({
              products: patch({
                data: append(response.data),
                count: state.products.count + response.count,
                paging: response.paging,
              }),
              selected: append(response.data),
            }),
          );
        }),
      );
  }

  @Action(LoadWorklists)
  loadWorklists(ctx: StateContext<ProductStateModel>) {
    return this.productsService.getWorklists().pipe(
      tap((workList) => {
        ctx.setState(
          patch<ProductStateModel>({
            workList: append(workList),
          }),
        );
      }),
    );
  }

  @Action(SelectAll)
  selectAll(ctx: StateContext<ProductStateModel>, { payload }: SelectAll) {
    const state = ctx.getState();
    const selected = payload ? state.products.data : [];
    ctx.patchState({
      selected,
    });
  }

  @Action(SelectOne)
  selectOne(ctx: StateContext<ProductStateModel>, { selectedProduct }: SelectOne) {
    const state = ctx.getState();
    const selected = R.includes(selectedProduct, state.selected)
      ? R.filter((product) => product.id !== selectedProduct.id, state.selected)
      : R.append(selectedProduct, state.selected);
    ctx.patchState({
      selected,
    });
  }

  @Action(ChangeWorkList)
  changeWorkList(ctx: StateContext<ProductStateModel>, { workListId }: ChangeWorkList) {
    ctx.patchState({
      workListId,
      selected: [],
    });

    ctx.dispatch(new LoadProduct({ workListId }));
  }

  @Action(RemoveFromWorklist)
  removeFromWorklist(ctx: StateContext<ProductStateModel>) {
    const state = ctx.getState();
    const workListId = state.workListId;
    const productIds = R.pluck('id', state.selected);

    return this.productsService.removeFromWorklist(workListId, productIds).pipe(
      tap(() => {
        ctx.patchState({
          selected: [],
        });

        ctx.dispatch(new LoadProduct({ workListId }));
      }),
    );
  }
  
  @Action(ClearProductState)
  clearProductState(ctx: StateContext<ProductStateModel>) {
    ctx.setState(INITIAL_STATE);
  }
}
