import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i1 from '@ngxs/store';
import { getActionTypeFromInstance, State, NgxsModule, createSelector } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
var __decorate$1 = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let ActionsExecutedState = class ActionsExecutedState {
  constructor(actions$) {
    this.actions$ = actions$;
    this.actionsExecutedSub = new Subscription();
  }
  ngxsOnInit({
    patchState,
    getState
  }) {
    this.actionsExecutedSub = this.actions$.pipe(tap(actionContext => {
      const actionType = getActionTypeFromInstance(actionContext.action);
      if (!actionType) {
        return;
      }
      let count = getState()?.[actionType] || 0;
      if (actionContext.status !== "DISPATCHED" /* ActionStatus.Dispatched */) {
        count++;
      }
      patchState({
        [actionType]: count
      });
    })).subscribe();
  }
  ngOnDestroy() {
    this.actionsExecutedSub.unsubscribe();
  }
  static {
    this.ɵfac = function ActionsExecutedState_Factory(t) {
      return new (t || ActionsExecutedState)(i0.ɵɵinject(i1.Actions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ActionsExecutedState,
      factory: ActionsExecutedState.ɵfac
    });
  }
};
ActionsExecutedState = __decorate$1([State({
  name: 'ngxs_actions_executed'
})], ActionsExecutedState);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionsExecutedState, [{
    type: Injectable
  }], () => [{
    type: i1.Actions
  }], null);
})();
var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
let ActionsExecutingState = class ActionsExecutingState {
  constructor(actions$) {
    this.actions$ = actions$;
    this._sub = new Subscription();
  }
  ngxsOnInit({
    patchState,
    getState
  }) {
    this._sub = this.actions$.pipe(tap(actionContext => {
      const actionType = getActionTypeFromInstance(actionContext.action);
      if (!actionType) {
        return;
      }
      let count = getState()?.[actionType] || 0;
      if (actionContext.status === "DISPATCHED" /* ActionStatus.Dispatched */) {
        count++;
      } else if (count > 0) {
        count--;
      }
      patchState({
        [actionType]: count
      });
    })).subscribe();
  }
  ngOnDestroy() {
    this._sub.unsubscribe();
  }
  static {
    this.ɵfac = function ActionsExecutingState_Factory(t) {
      return new (t || ActionsExecutingState)(i0.ɵɵinject(i1.Actions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ActionsExecutingState,
      factory: ActionsExecutingState.ɵfac
    });
  }
};
ActionsExecutingState = __decorate([State({
  name: 'ngxs_actions_executing'
})], ActionsExecutingState);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ActionsExecutingState, [{
    type: Injectable
  }], () => [{
    type: i1.Actions
  }], null);
})();
class NgxsActionsExecutingModule {
  static forRoot() {
    return {
      ngModule: NgxsActionsExecutingModule
    };
  }
  static {
    this.ɵfac = function NgxsActionsExecutingModule_Factory(t) {
      return new (t || NgxsActionsExecutingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgxsActionsExecutingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [NgxsModule.forFeature([ActionsExecutingState, ActionsExecutedState])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsActionsExecutingModule, [{
    type: NgModule,
    args: [{
      imports: [NgxsModule.forFeature([ActionsExecutingState, ActionsExecutedState])]
    }]
  }], null, null);
})();
function actionsExecutingFn(actionTypes, state) {
  if (!actionTypes || actionTypes.length === 0) {
    if (Object.keys(state).length === 0) {
      return null;
    }
    return state;
  }
  return actionTypes.reduce((acc, type) => {
    const actionType = getActionTypeFromInstance(type);
    if (!actionType) {
      return acc;
    }
    if (state[actionType]) {
      return {
        ...acc,
        [actionType]: state[actionType]
      };
    }
    return acc;
  }, null);
}
function actionsExecuting(actionTypes) {
  return createSelector([ActionsExecutingState], state => {
    return actionsExecutingFn(actionTypes, state);
  });
}
function hasActionsExecuting(actionTypes) {
  return createSelector([ActionsExecutingState], state => {
    const result = actionsExecutingFn(actionTypes, state);
    return result === null ? false : Object.values(result).some(value => value > 0);
  });
}
function actionsExecuted(actionTypes) {
  return createSelector([ActionsExecutedState], state => {
    if (!actionTypes || actionTypes.length === 0) {
      if (Object.keys(state).length === 0) {
        return null;
      }
      return state;
    }
    return actionTypes.reduce((acc, type) => {
      const actionType = getActionTypeFromInstance(type);
      if (!actionType) {
        return acc;
      }
      if (state[actionType]) {
        return {
          ...acc,
          [actionType]: state[actionType]
        };
      }
      return acc;
    }, null);
  });
}

/**
 * Generated bundle index. Do not edit.
 */

export { NgxsActionsExecutingModule, actionsExecuted, actionsExecuting, hasActionsExecuting };
