import { EMPTY_PAGING, OffsetPagingWrapper, PagingOrder, SortingOptions } from '@clover/core/helpers/paging';
import { AssociatedCompany } from '@clover/network-v2/network/accounts/state/accounts.entities';
import ProductWorklist from '@clover/products/models/product-worklist';

export enum ProductAccess {
  Public = 'Public',
  Private = 'Private',
}

export type ProductSortFields = 'name' | 'accessType' | 'vendor' | 'internalItemNumber';

export interface DeleteProductFromWorkList {
  deleteProductIds: number[];
  isWorklistDeleted: boolean;
}

export interface IProduct {
  accessType: ProductAccess;
  id: number;
  internalItemNumber: string;
  marketingLogoUrl?: string;
  marketingMessage?: string;
  name: string;
  status: string;
  vendorCompanyId: string;
  vendorCompanyName: string;
}

export interface ProductStateModel {
  query: string;
  products: OffsetPagingWrapper<IProduct>;
  selected: IProduct[];
  sorting: SortingOptions<ProductSortFields>;
  workList: ProductWorklist[];
  workListId?: number | null;
}

// ToDo [Andrii S.] remove connected companies from this state, to prevent search filter logic overlap
export const INITIAL_STATE: ProductStateModel = {
  query: '',
  products: EMPTY_PAGING,
  selected: [],
  sorting: {
    orderBy: 'name',
    order: PagingOrder.Ascending,
  },
  workList: [
    {
      id: 0,
      name: 'All products',
    },
  ],
  workListId: null,
};
